export const BookingType = {
  WebAdmin: { code: 'WEB_ADMIN', name: 'Đặt Qua Website ADMIN' },
  AppDriver: { code: 'APP_DRIVER', name: 'Đặt Qua App DRIVER' },
  WebCustomer: { code: 'WEB_CUSTOMER', name: 'Đặt Qua Website CUSTOMER' },
  WebCustomerQR: {
    code: 'WEB_CUSTOMER_QR',
    name: 'Đặt Qua Website CUSTOMER Bằng Cách Quét Mã QR Từ Tài Xế',
  },
  AppCustomer: { code: 'APP_CUSTOMER', name: 'Đặt Qua App CUSTOMER' },
  AppCustomerQR: {
    code: 'APP_CUSTOMER_QR',
    name: 'Đặt Qua App CUSTOMER Bằng Cách Quét Mã QR Từ Tài Xế',
  },
  Partner: { code: 'PARTNER', name: 'Đối tác đặt chuyến' },
  PartnerQuick: {
    code: 'PARTNER_QUICK',
    name: 'Đối tác đặt chuyến (Đặt Nhanh)',
  },
  PartnerQR: {
    code: 'PARTNER_QR',
    name: 'Hành khách (Quét QR)',
    description: 'Khách hàng quét mã QR của partner',
  },
};

export const SourceType = {
  Admin: {
    code: 'ADMIN',
    name: 'Tổng đài viên',
    description: 'Tổng đài viên đặt chuyến',
  },
  Driver: { code: 'DRIVER', name: 'Tài xế', description: 'Tài xế đặt chuyến' },
  Customer: {
    code: 'CUSTOMER',
    name: 'Hành khách',
    description: 'Khách hàng đặt chuyến',
  },
  Partner: {
    code: 'PARTNER',
    name: 'Đối tác',
    description: 'Đối tác đặt chuyến',
  },
};

export const OrderType = {
  Now: {
    code: 'NOW',
    name: 'Đi ngay',
    description: 'Hành khách đặt chuyến đi ngay',
  },
  Timer: {
    code: 'TIMER',
    name: 'Hẹn giờ',
    description: 'Hành khách hẹn giờ cho chuyến đi',
  },
};
export const MessengerType = {
  Sms: { code: 'SMS', name: 'Tin nhắn SMS' },
  Whatsapp: { code: 'WHATSAPP', name: 'Whatsapp' },
};

export const OrderStatus = {
  New: {
    value: 'NEW',
    label: 'Chờ tài xế xác nhận',
    eLabel: 'Confirmed',
    color: 'primary',
  },
  Wait: {
    value: 'WAIT',
    label: 'Chờ tài xế xác nhận',
    eLabel: 'Confirmed',
    color: 'info',
  },
  Confirm: {
    value: 'CONFIRM',
    label: 'Tài xế đang đến',
    eLabel: 'Driver on the way',
    color: 'secondary',
  },
  Doing: {
    value: 'DOING',
    label: 'Đang thực hiện',
    eLabel: 'In progress',
    color: 'warning',
  },
  Complete: {
    value: 'COMPLETE',
    label: 'Đã hoàn thành',
    eLabel: 'Completed',
    color: 'success',
  },
  Cancel: {
    value: 'CANCEL',
    label: 'Đã hủy',
    eLabel: 'Cancelled',
    color: 'error',
  },
};

export const PaymentType = {
  Cash: {
    code: 'CASH',
    name: 'Tiền mặt',
    description: 'Hành khách thanh toán tiền mặt',
  },
  VNPAY: {
    code: 'VNPAY',
    name: 'VnPay',
    description: 'Hành khách thanh toán bằng ví điện tử VN Pay',
  },
  MBBANK: {
    code: 'MBBANK',
    name: 'MB Bank',
    description: 'Hành khách thanh toán bằng MB Bank',
  },
};
export const PaymentStatus = {
  NonPaid: {
    code: 'NON_PAID',
    name: 'Chưa thanh toán',
    description: 'Hành khách Chưa thanh toán',
  },
  Paid: {
    code: 'PAID',
    name: 'Đã thanh toán',
    description: 'Hành khách Đã thanh toán',
  },
};

export const ERROR_VALIDATE = 'Dữ liệu không hợp lệ!';
export const ERROR_NOT_FOUND_DATA = 'Không tìm thấy dữ liệu!';

export const SOCKET_CONFIG = {
  HAS_CHANGE_ORDER_INSIDE: 'HAS_CHANGE_ORDER_INSIDE',
  PAYMENT_CUSTOMER_ORDER: 'PAYMENT_CUSTOMER_ORDER',
  TRACKING_CAR_LIST: 'TRACKING_CAR_LIST',
  PAYMENT_EWALLET_ORDER: 'PAYMENT_EVALLET_ORDER',
};

export const SocketType = {
  CreateData: 'CreateData',
  CreateDataQuick: 'CreateDataQuick',
  UpdateStatusWait: 'UpdateStatusWait',
  UpdateStatusConfirm: 'UpdateStatusConfirm',
  UpdateStatusReject: 'UpdateStatusReject',
  UpdateStatusDoing: 'UpdateStatusDoing',
  UpdateStatusCancel: 'UpdateStatusCancel',
  UpdateStatusComplete: 'UpdateStatusComplete',
  UpdateData: 'UpdateData',
  UpdatePaymentCash: 'UpdatePaymentCash',
  UpdatePayment: 'UpdatePayment',
  UpdatePaymentVNPaySuccess: 'UpdatePaymentVNPaySuccess',
  UpdatePaymentMbBankSuccess: 'UpdatePaymentMbBankSuccess',
};

export const NotifyStatus = {
  READ: 'DaDoc',
  NOT_READ: 'ChuaDoc',
};
